.grey-sep-full{
  height: .1rem;
  width: 100%;
  background-color: $color5;
}

.red-sep{
  border-top-color: var(--color2);
  width: 12rem;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 2rem;
}