.page_depot{
  .h2{
    font-size: 3rem;
    .status-open{
      color: #87c15b;
    }
    .status-closed{
      color: #e40828;
    }
    .icon{
      font-size: 3rem;
    }
  }
  .h3{
    font-size: 2rem;
    margin-top: 2rem;
    .icon{
      font-size: 3rem;
      margin-right: 1.2rem;
    }
  }
  .mini{
    display: inline-block;
    margin-left: .8rem;
    font-size: 60%;
  }
  .schedule{
    > div{
      margin-bottom: 1rem;
    }
    .day{
      display: inline-block;
      width: 15rem;
      font-weight: 700;
    }
  }
  .map_c{
    position: relative;
    .button{
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      z-index: 400;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .page_depot{
    .h2{
      font-size: 2.5rem;
    }
    .schedule .day{
      width: 8.5rem;
    }
  }
}