.header_content {
  .line1,
  .line2{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .line1{
    padding: 1.6rem 0;
    font-weight: 700;
    .logo_mobile{
      display: none;
    }
    a,
    .js_lien-cache,
    .lien-off {
      color: $color16;
      &:hover{
        color: var(--color1);
      }
    }
    .icon{
      color: var(--color1);
      font-size: 2rem;
    }
    .address_header{
      margin-right: 2.7rem;
      .trouver_text{
        display: none;
      }
    }
    .ref_agence{
      display: flex;
    }
    .horaires_header{
      max-width: 18rem;
      text-align: center;
      line-height: 1;
    }
    .status{
      font-weight: 400;
      margin: 0 .3rem;
      &.status-open{
        color: #87C15B;
      }
      &.status-closed{
        color: #e40828;
      }
    }
    .shop-action-mobile{
      display: none;
    }
  }
  .line2{
    height: 11.4rem;
    position: relative;
    .icon{
      font-size: 2.8rem;
    }
    .logo-header img{
      max-width: 25rem;
      max-height: 9rem;
      object-fit: contain;
    }
    .search-bar{
      display: flex;
      width: calc(100% - 60rem);
      height: 6.5rem;
      .kcustom-select{
        width: 17.4rem;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span{
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          font-size: 1.6rem;
        }
        &::after{
          transition: transform .3s;
          top: 2.6rem;
        }
      }
      input{
        width: calc(100% - 23rem);
        padding-left: 4rem;
        font-size: 1.8rem;
        font-weight: 600;
        color: $color16;
        background-color: #EFEFEF;
        border-top: .1rem solid #CBCBC9;
        border-bottom: .1rem solid #CBCBC9;
        border-left: 0;
        border-right: 0;
        &:focus{
          outline: none;
        }
      }
      .submit_search{
        @include flex_center;
        width: 6.5rem;
        border: .1rem solid #CBCBC9;
        border-left: 0;
        background-color: #efefef;
        .icon{
          color: var(--color1);
          transition: color .3s;
          }
        &:hover .icon{
          color: var(--color2);
        }
        &:focus{
          outline: none;
        }
      }
    }
  }
  .shop-action{
    margin-left: -2rem;
    display: flex;
    a,
    .js_lien-cache,
    .lien-off {
      margin-left: 2rem;
      margin-right: 2rem;
      color: var(--color1);
      &:hover{
        color: var(--color2);
      }
    }
    .circle_c{
      position: relative;
      .circle{
        position: absolute;
        top: .2rem;
        right: -1.4rem;
        width: 1.8rem;
        height: 1.8rem;
        @include flex_center;
        color: #fff;
        background-color: var(--color2);
        border: .1rem solid var(--color2);
        border-radius: 50%;
        font-size: 1rem;
        .icon{
          font-size: .8rem;
          line-height: 1;
          color: #fff;
          transition: color .3s;
        }
        &:hover{
          background-color: #fff;
          color: var(--color2);
          .icon{
            color: var(--color2);
          }
        }
      }
      &.link_account_header{
        #css-icone-deconnexion{
          padding-top: 1px;
        }
        margin-right: .8rem;
      }
      .name_header{
        color: var(--color1);
        position: absolute;
        right: 0;
        bottom: -2.5rem;
        left: 0;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.2;
        text-align: center;
      }
    }
  }
}

.menu_recherche_c,
.menu_produit_c{
  position: absolute;
  top: 9rem;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 401;
  opacity: 0;
  visibility: hidden;
  .menu_produit{
    display: flex;
    background-color: $color6;
    max-height: 60rem;
    .menu_produit_level{
      width: calc((100% - 50.1rem) / 2);
      overflow-y: auto;
      .h3{
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
    .menu_produit_level1{
      width: 50.1rem;
      background-color: $color11;
      padding: 1rem 2rem 3rem 3rem;
      > div{
        margin-top: 3rem;
        .h3{
          color: #fff;
          &::before{
            content:"";
            display: inline-block;
            height: 1.8rem;
            width: .6rem;
            background-color: var(--color4);
            vertical-align: top;
            margin-right: 1rem;
            opacity: 0;
            visibility: hidden;
          }
          &:hover{
            color: var(--color4);
            &::before{
              opacity: 1;
              visibility: visible;
            }
          }
          &.current{
            &::before{
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
    .menu_produit_level2,
    .menu_produit_level3 {
      padding: 2rem 3rem 2rem 5rem;
      .h3{
        color: var(--color4);
        margin-bottom: 3rem;
      }
      a,
      .js_lien-cache,
      .lien-off {
        @include flex_between;
        max-width: 30rem;
        margin-top: 1.2rem;
        span{
          color: $color11;
          transition: color .3s;
          &.icon{
            display: inline-block;
            font-size: 1rem;
            vertical-align: sub;
          }
        }
        &.current span,
        &:hover span{
          color: var(--color4);
        }
      }
    }
    .menu_produit_level2{
      border-right: .1rem solid #707070;
    }
  }
}

.menu_recherche_c{
  a:hover,
  .js_lien-cache:hover,
  .lien-off:hover {
    color: var(--color4);
  }
  .menu_produit{
    .menu_produit_level1 > div{
      margin-top: 1.2rem;
      .h3{
        color: #fff;
        margin-top: 2.8rem;
        margin-bottom: 2.2rem;
        cursor: default;
        &:hover{
          color: #fff;
        }
        &:hover::before{
          opacity: 0;
          visibility: hidden;
        }
      }
      .h4{
        color: #fff;
        font-size: 1.8rem;
        margin-left: 1.6rem;
        text-align: left;
        &:hover,
        .highlight{
          color: var(--color4);
        }
      }
    }
    .menu_recherche_content{
      width: calc(100% - 50.1rem);
      padding: 3.1rem 4.6rem 2.5rem;
      max-height: 40rem;
      overflow: auto;
      margin-bottom: 3rem;
      .header_recherche{
        display: flex;
        align-items: baseline;
        .h3{
          font-size: 1.8rem;
          color: $color11;
          font-weight: 600;
          margin-right: 2.6rem;
          margin-left: 1rem;
          margin-bottom: 2rem;
        }
        a,
        .js_lien-cache,
        .lien-off {
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
      .recherche_list .row{
        .card_product_recherche {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-color: #fff;
          border-radius: .8rem;
          padding: 1rem;
          font-weight: 600;
          line-height: 1.2;
          margin-bottom: 1rem;
          .img_product_recherche{
            width: 6.2rem;
            min-width: 6.2rem;
            margin-right: 1rem;
            img{
              width: 6.2rem;
              height: 6.2rem;
              object-fit: contain;
            }
          }
          .name_product_recherche{
            height: 3.6rem;
            overflow: hidden;
            word-break: break-all;
          }
          .infos_product_recherche{
            display: flex;
            .stock_block{
              margin-left: 2rem;
              .icon-stock{
                width: 1.2rem;
                height: 1.2rem;
                min-width: 1.2rem;
                margin-bottom: -0.2rem;
              }
            }
          }
        }
      }
    }
  }
}

.line2.open{
  .kcustom-select::after{
    transform: rotate(180deg);
  }
  .menu_produit_c{
    opacity: 1;
    visibility: visible;
  }
}

/* Menu principal */
#primary_nav_wrap {
  border-bottom: .4rem solid var(--color2);

  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  ul.first_level {
    display: flex;
    justify-content: space-around;
    height: 100%;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #302D29;
    > li{
      &.dropdown::after{
        content: "\EA04";
        font-family: kfont;
        position: absolute;
        top: 52%;
        right: -2rem;
        transform: translateY(-50%);
        display: block;
        font-size: .9rem;
        color: #302d29;
      }
      > a{
        display: flex;
        align-items: center;
        height: 6rem;
      }
    }
    a,
    .js_lien-cache,
    .lien-off {
      color: #302D29;
      &:hover{
        color: var(--color1);
      }
    }
    .current a,
    .current .js_lien-cache,
    .current .lien-off {
      color: var(--color2);
    }
    .second_level{
      position: absolute;
      left: 50%;
      top: 6rem;
      background-color: #fff;
      z-index: 99;
      padding: 0 1rem 1.2rem;
      min-width: 22rem;
      overflow: hidden;
      transform: translateX(-50%) scaleY(0);
      transform-origin: top;
      transition: transform .3s;
      @include box-shadow;
      ul{
        padding-right: 0;
        li{
          margin-top: .8rem;
          margin-bottom: .8rem;
          line-height: 1.1;
          width: 100%;
          a{
            font-size: 1.5rem;
            text-transform: none;
            color: #302d29;
            &:hover{
              color: var(--color1);
            }
          }
        }
      }
    }
    li.dropdown:hover{
      .second_level {
        transform: translateX(-50%) scaleY(1);
      }
    }
  }
}


/* Sticky */
#header.sticky{
  position: fixed;
  top: 0;
  left: -.4rem;
  width: calc(100% + .8rem);
  background-color: #fff;
  z-index: 9999;
  border-bottom: none;
  box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .3);
  .header_content .grey-sep-full,
  .header_content .line1,
  .header_content .line3 {
    display:none !important;
  }
  .header_content .line2 {
    height: 7.4rem;
    .logo-header img{
      max-height: 6rem;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }
  .header_content .menu_produit_c,
  .header_content .menu_recherche_c{
    top: 7.4rem;
  }
  .name_header{
    display: none;
  }
}

#header.sticky + #contenu{
  margin-top: 17.6rem;
}


@media (max-width: $breakpoint-xl) {
  .menu_recherche_c .menu_produit{
    .menu_produit_level1{
      width: 35rem;
    }
    .menu_recherche_content{
      width: calc(100% - 35rem);
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .menu_recherche_c .menu_produit{
    .menu_produit_level1{
      width: 30rem;
      padding-left: 1rem;
      padding-top: .4rem;
      > div .h3{
        margin-top: 2rem;
        margin-bottom: 1.8rem;
      }
    }
    .menu_recherche_content{
      width: calc(100% - 30rem);
      padding: 1rem 2.5rem 1.5rem;
      .header_recherche{
        flex-wrap: wrap;
        .h3{
          width: 100%;
          margin-bottom: .5rem;
        }
        a,
        .js_lien-cache,
        .lien-off {
          margin-left: 2rem;
          margin-bottom: 1.2rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  #header.sticky + #contenu{
    margin-top: 12rem;
  }
  #primary_nav_wrap {
    width: auto;

    ul.first_level {
      display: none;
    }
  }
}


@media (max-width: $breakpoint-sm) {
  #logo{
    position: absolute;
    top: 5px;
    left: 60px;
    width: 150px;
  }
  .header_top .header_content {
    margin: 0;
  }
  .sticky #primary_nav_wrap {
    &,
    ul.first_level {
      height: 5rem;
    }
  }
  #header .header_top.sticky .header_content .line1 {
    display:block !important;
    #acces { display:none !important; }
    #menu_launcher {
      position: absolute;
      left: 12px;
      top: 6px;
      margin-left: auto;
    }
  }
  .menu_recherche_c .menu_produit {
    flex-wrap: wrap;
    .menu_produit_level1,
    .menu_recherche_content{
      width: 100%;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  #logo{
    top: 5px;
    left: 50px;
    width: 110px;
  }
  .boutique {
    position: absolute;
    top: 3px;
    right: 10px;

    .button {
      min-width: auto;
      font-size: 12px;
    }
  }
  #header.sticky .header_content .menu_recherche_c{
    top: 6.3rem;
  }
  .menu_recherche_c {
    top: 7.5rem;
    .menu_produit{
      .menu_recherche_content{
        padding: 1rem 1.5rem 1.5rem;
        .header_recherche .h3{
          margin-right: 1rem;
        }
      }
      .menu_produit_level1{
        padding-bottom: 2rem;
        > div{
          margin-top: .4rem;
          .h3{
            padding-left: 1.5rem;
            margin-bottom: 1rem;
            &::before{
              content: none;
            }
          }
          .h4{
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}



/* ACCES */
#acces {
  ul {
    list-style: none;
    padding: 0;
  }
  #accesmenu {
    margin: auto 0 auto auto;
  }
  ul.first_level {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    & > li {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      position: relative;
      a,
      .js_lien-cache,
      .lien-off {
        color: $color8;
        font-size: 14px;
        font-weight: 500;
        transition: all .2s ease-in;
        @media (max-width: $breakpoint-sm) {
          font-size: 12px;
        }

        > .icon,
        > .text {
          vertical-align: baseline;
        }


        &:hover,
        &:focus {
          color: $color6;
        }
      }
      &.dropdown .icon {
        font-size:9px;
      }
    }

    & > li.dropdown:hover > .second_level,
    & > li.dropdown > a:hover ~ .second_level,
    & > li.dropdown > a:focus ~ .second_level,
    & > li.dropdown > .js_lien-cache:hover ~ .second_level,
    & > li.dropdown > .js_lien-cache:focus ~ .second_level,
    & > li.dropdown > .lien-off:hover ~ .second_level,
    & > li.dropdown > .lien-off:focus ~ .second_level {
      opacity: 1;
      visibility: visible;
    }
    & > li.dropdown:hover > a > .icon,
    & > li.dropdown:hover > .js_lien-cache > .icon,
    & > li.dropdown:hover > .lien-off > .icon {
      opacity: 1;
      visibility: visible;
    }
  }
  .second_level {
    position: absolute;
    z-index: 2;
    top: calc(100% - 10px);
    left: 0;
    width:200px;
    background-color: var(--color2);
    color: $color6;
    cursor: initial;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
    > ul {
      padding: 10px 15px;
    }
  }
}



/* BODY */
@media (max-width: $breakpoint-sm) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (min-width: $breakpoint-xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: $breakpoint-xl;
  }
}

@media (max-width: $breakpoint-xl) {
  .menu_produit_c .menu_produit .menu_produit_level,
  .menu_produit_c .menu_produit .menu_produit_level1{
    width: calc(100% / 3);
  }
}

@media (max-width: $breakpoint-lg) {
  .menu_produit_c .menu_produit .menu_produit_level1{
    padding-left: 1.5rem;
  }
  .header_content .line2 .search-bar{
    width: calc(100% - 50rem);
    .kcustom-select{
      width: 24rem;
    }
    input{
      width: calc(100% - 19rem);
      padding-left: 2rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  #primary_nav_wrap{
    height: 0;
  }
  .header_content .line1 .address_header{
    margin-right: 1rem;
     .trouver_text {
      display: inline-block;
    }
     .trouver_depot {
      display: none;
    }
  }
  .header_content .line2{
    .logo-header img{
      max-width: 14rem;
    }
    .shop-action a,
    .shop-action .js_lien-cache,
    .shop-action .lien-off {
      margin-left: 1.3rem;
      margin-right: 1.3rem;
    }
    .search-bar{
      width: calc(100% - 39rem);
      .kcustom-select{
        display: none;
      }
      input{
        width: 100%;
        border-left: .1rem solid #cbcbc9;
        padding-left: 1rem;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .header_content{
    .line1{
      .logo_mobile{
        display: block;
        img{
          width: 16rem;
          height: 7rem;
          object-fit: contain;
        }
      }
      .address_header,
      .header_depot_select{
        display: none;
      }
      .content{
        display: none;
      }
    }
    .line2{
      #logo{
        display: none;
      }
      .search-bar{
        width: calc(100% - 24rem);
        margin-right: 3rem;
      }
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .header_content{
    .line1{
      padding: .8rem 0;
      .logo_mobile{
        width: 14rem;
      }
      .ref_agence,
      .horaires_header,
      .contact_header{
        display: none;
      }
      .shop-action-mobile{
        display: flex;
        a,
        .js_lien-cache,
        .lien-off {
          margin-left: .5rem;
          margin-right: .5rem;
          .icon{
            font-size: 2.4rem;
          }
        }
      }
    }
    .line2{
      height: 7rem;
      .shop-action > *{
        display: none;
      }
      .search-bar{
        width: calc(100% - 14rem);
        margin-right: 6rem;
        height: 4.5rem;
      }
      .icon{
        font-size: 2.4rem;
        line-height: 1;
      }
    }
  }
  #header.sticky{
    + #contenu{
      margin-top: 7rem;
    }
    .header_content .line2{
      height: 6.3rem;
      .shop-action .link_cart_header{
        display: block;
      }
    }
  }
}