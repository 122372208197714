.alert-content{
  padding-left: 0;
  margin-bottom: 0;
  .alert-item {
    display: flex;
    align-items: center;
    background-color: $color7;
    border-left: .7rem solid var(--color2);
    padding: 1rem;
    margin-bottom: 1rem;
    .circle{
      margin-right: 1.8rem;
      margin-left: 3.6rem;
    }
    .text{
      color: $color11;
      font-weight: 600;
    }
  }
}

.page_panier{
  .h1{
    font-size: 2.4rem;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
    &.main-title{
      font-size: 3.5rem;
    }
  }
  .h2{
    font-size: 1.8rem;
    font-weight: 700;
  }
  .circle{
    @include flex_center;
    width: 1.8rem;
    height: 1.8rem;
    color: #fff;
    background-color: #b20c0c;
    border: .1rem solid #b20c0c;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: 700;
  }
  hr{
    border-top-width: .2rem;
    &.light{
      border-top-width: .1rem;
      border-top-color: rgba(0, 0, 0, .09);
    }
  }
}

#valider_panier{
  margin-bottom: 2rem;
}

.valid_panier_box select {
  -webkit-appearance: auto;
}

.valid_panier_box{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  input,
  select,
  textarea{
    width: 40rem;
    border: .1rem solid #707070;
    padding-left: .8rem;
  }
  input,
  select{
    height: 4rem;
  }
  textarea{
    resize: none;
    height: 8rem;
    padding-top: .6rem;
  }
  .h2{
    margin: 0;
  }
  .icon{
    font-size: 3rem;
    margin-right: 1.5rem;
  }
}

.product_line{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  .block_panier_img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 8rem;
    img{
      max-height: 8rem;
    }
  }
  .block_panier_desc{
    width: calc(100% - 60rem);
    a{
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: .6rem;
    }
    .block_panier_info{
      text-transform: uppercase;
    }
  }
  .block_panier_alert_message,
  .block_panier_alert{
    display: flex;
    align-items: center;
  }
  .block_panier_unit_price,
  .block_panier_total_price{
    color: #000;
    font-weight: 700;
  }
  .block_panier_unit_price{
    width: 10rem;
    text-align: center;
  }
  .block_panier_total_price{
    width: 12rem;
    text-align: center;
    font-size: 1.8rem;
  }
  .icon-supp{
    color: #000;
    font-size: 2rem;
    cursor: pointer;
    height: 2.5rem;
    width: 3rem;
    text-align: center;
    transition: color .3s;
    &:hover{
      color: var(--color2);
    }
  }
  .circle{
    margin-right: 1.5rem;
    cursor: default;
  }
}

/* Step panier */
.step-panier{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  li{
    display: flex;
    .step-panier-item{
      display: flex;
      align-items: center;
      .circle{
        margin-right: 1rem;
      }
      .text{
        padding-right: 3rem;
      }
      &.active .text{
        color: var(--color2);
        text-decoration: underline;
      }
    }
  }
}

.panier-recap{
  .panier-recap-content{
    background-color: $color6;
    padding: 2rem;
    .line_recap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;
      .icon-promo{
        font-size: 1.8rem;
      }
      .amount_total{
        color: var(--color1);
        font-size: 2.3rem;
        font-weight: 700;
      }
      .lowlight{
        color: #777;
        font-size: 1.2rem;
        margin-top: -.4rem;
      }
    }
  }
  .cgv_block{
    padding-top: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    input{
      vertical-align: middle;
      margin-right: .7rem;
      transform: scale(1.8);
    }
    label{
      display: inline;
    }
  }
}


#espaceClientInt .bas_panier {
  position: relative;
  background-color: $color7;
  border: 1px solid $color10;
  padding: 20px;
  margin: 1em 0;
  min-height: 245px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  .h4 {
    text-align: left;
    label{
      cursor: pointer;
    }
  }
  input[type="radio"]:checked + label{
    color: var(--color2);
  }
  .em_radiobox{
    font-size: 1.4rem;
    font-weight: 300;
    color: $color16;
  }
  select{
    height: 5rem;
    width: 100%;
    max-width: 39rem;
    border-radius: .4rem;
    margin-left: auto;
    margin-right: auto;
  }
  input[type="date"]{
    height: 5rem;
    width: 20rem;
    text-align: center;
    border: .1rem solid #ddd;
    border-radius: .4rem;
  }
}


.remise-recap-content{
  font-weight: 600;
  margin-top: 2rem;
  .libcr{
    color: #524842;
  }
  .form_bdr{
    display: flex;
    input{
      height: 5rem;
      border: .1rem solid #707070;
      &.bdr_code{
        width: calc(100% - 16rem);
        padding-left: .8rem;
        margin-right: 1rem;
        &::placeholder{
          text-transform: uppercase;
          color: $color12;
        }
      }
      &.button-gris{
        cursor: pointer;
        width: 15rem;
        padding: 0;
      }
    }
  }
}

.panier-listing{
  .h1{
    text-align: left;
    margin-top: 4rem;
  }
  .panier-listing-recap .product_line + .product_line{
    border-top: 1px solid #ddd;
    padding-top: 1rem;
  }
}

.recap_adresse_line{
  display: flex;
  align-items: center;
  line-height: 1.5;
  .label{
    width: 16rem;
    font-weight: 700;
    line-height: 1.2;
  }
}

/* DRIVE */
#espaceClientInt .drive-c{
  .choix-depot {
    .h4 {
      text-align: center;
      margin-bottom: .8rem;
      .icon {
        font-size: 3rem;
        margin-right: .8rem;
      }
    }
    .kcustom-select{
      min-width: 26rem;
      margin-left: auto;
      margin-right: auto;
      &::after{
        top: 2rem;
      }
      select{
        font-size: 1.6rem;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
  .infos-depot{
    .h4 .icon{
      font-size: 2.5rem;
      margin-right: .8rem;
    }
    .adresse-depot p{
      margin-bottom: 0;
    }
    select{
      text-align: center;
      padding-left: 0;
      margin-left: 0;
      max-width: none;
    }
  }
  #map-drive{
    height: 35rem;
  }
  .icon-warning{
    font-size: 2rem;
    margin-right: .6rem;
  }
}

.register_panier {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  margin-top: 2rem;
  margin-bottom: 2rem;
  a::after{
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 8px;
    border-color: transparent transparent transparent var(--color1);
    margin-left: 1.5rem;
    transition: margin-left .5s;
  }
  a:hover{
    &::after{
      margin-left: 1rem;
    }
  }
  &:has(.label_panier_nom){
    text-align: left;
    .panier_nom{
      width: 91%;
    }
    a {
      display: inline-block;
      padding: 0.5rem 1rem;
      border: 2px solid;
      border-radius: 0.5rem;
      text-decoration: none;
      &::after{
        transition: none;
        margin: 0;
      }

    }
  }
}

.cta_panier a{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.8rem;
  .icon{
    font-size: 3rem;
    line-height: 0;
    margin-right: 1.5rem;
  }
}

.cta_panier + .cta_panier{
  margin-top: 1rem;
}

#panier_action{
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .button{
    padding: 1.4rem 3.5rem;
    margin-bottom: .5rem;
    + .button{
      margin-left: .8rem;
    }
  }
  .button-filed {
    @include button-filed(var(--color1));
    padding: 1.35rem 3.5rem;
  }
}

#espaceClientInt .blocs-paiement-c{
  &.bas_panier{
    min-height: 0;
  }
  .h3{
    margin-top: 0;
  }
  .h4{
    text-align: center;
  }
  input{
    display: none;
  }
  label{
    display: block;
    text-align: center;
    border: .1rem solid #cbcbc9;
    border-radius: 4px;
    line-height: 1;
    height: 11rem;
    cursor: pointer;
  }
  input:checked + label{
    border-color: var(--color1);
    @include box_shadow;
  }
  .icon{
    font-size: 6rem;
    color: #777;
  }
  img{
    max-width: 5rem;
  }
}

@media (max-width: $breakpoint-xl) {
  .product_line{
    flex-wrap: wrap;
    .block_panier_desc{
      width: calc(100% - 10rem);
      margin-bottom: 1rem;
    }
  }
  .remise-recap-content .form_bdr{
    input.bdr_code{
      width: calc(100% - 14rem);
    }
    input.button-gris{
      width: 13rem;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .remise-recap-content .form_bdr{
    input.bdr_code{
      width: calc(100% - 12rem);
    }
    input.button-gris{
      width: 11rem;
    }
  }
  #espaceClientInt .bas_panier{
    select,
    input[type="date"]{
      width: 100%;
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
    }
    .input-effect{
      max-width: none;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .page_panier .h1{
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .valid_panier_box{
    flex-wrap: wrap;
    label{
      width: 100%;
    }
  }
  .panier-recap-content{
    padding: 1rem;
  }
  .remise-recap-content .form_bdr{
    flex-wrap: wrap;
    input.bdr_code{
      width: 100%;
      margin-bottom: 1rem;
    }
    input.button-gris{
      margin-left: auto;
      margin-right: auto;
      height: 4rem;
    }
  }
  .register_panier{
    font-size: 1.4rem;
  }
  .cta_panier a{
    font-size: 1.5rem;
    line-height: 1.2;
  }
}

@media (max-width: $breakpoint-xs) {
  .page_panier .h1{
    margin-top: 1rem;
  }
  .step-panier{
    flex-direction: column;
    padding-left: 0;
    li{
      justify-content: center;
      margin-bottom: .5rem;
      .step-panier-item{
        width: 11rem;
        .text{
          padding-right: 0;
        }
      }
    }
  }
  .product_line{
    .block_panier_img{
      margin-left: auto;
      margin-right: auto;
    }
    .block_panier_desc{
      width: 100%;
    }
    .block_panier_total_price{
      width: 80%;
      margin-top: 1rem;
    }
  }
}

.css-ordre-enlevement{
  order: 0;
}

.css-ordre-livraison{
  order: 1;
}