.push-home {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: var(--color1);
  padding: 1rem 1rem 1rem 5rem;
  z-index: 100;
  width: 100%;
  justify-content: center;
  font-weight: 500;
}
a .push-home{
  text-decoration: underline;
  color: #fff;
  text-underline-offset: 5px;
}
.push-home p {
  margin-bottom: 0;
}
@media (max-width: $breakpoint-sm) {
  .hide_desktop{
    display: flex!important;
  }
  .hide_mobile{
    display: none!important;
  }
}
@media (min-width: $breakpoint-sm) {
  .hide_desktop{
    display: none!important;
  }
  .hide_mobile{
    display: flex!important;
  }
}