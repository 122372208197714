.overlay-modal{
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .3);
  z-index: 11;
}

.modal_lateral{
  position: fixed;
  top: 0;
  right: -52rem;
  bottom: 0;
  width: 52rem;
  background-color: #fff;
  z-index: 9999;
  transition: right .5s;
  .h3{
    height:6rem;
    @include flex_between;
    border-bottom: .3rem solid var(--color2);
    padding-left: 3rem;
    padding-right: 3rem;
    .icon-close{
      font-size: 1.6rem;
      cursor: pointer;
      &:hover{
        color: $color11;
      }
    }
  }
}

body.open-modal{
  .overlay-modal{
    display: block;
  }
  .open-content-modal {
    right: 0;
    overflow-y: auto;
  }
}

/* Page produit */
.modal_lateral{
  .button,
  .button-filed{
    display: block;
  }
  .button-filed{
    margin-top: 2rem;
    @include button-filed(var(--color1));
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .15rem;
    text-align: center;
  }
}

/* Modale dispo */
.modal_dispo{
  width: 96rem;
  right: -96rem;
  .modal-lateral-content{
    padding: 2.5rem;
  }
  .pres_product{
    display: flex;
    margin-bottom: 3rem;
    .product_modal_name{
      color: var(--color1);
      font-size: 1.8rem;
      font-weight: 600;
    }
    .modal_img_product_c{
      @include flex_center;
      height: 16rem;
      width: 19rem;
      margin-right: 4rem;
      @include box_shadow;
      img{
        max-width: 12rem;
        min-height: 100%;
      }
    }
  }
  .h5{
    font-size: 1.6rem;
    font-weight: 700;
    color: $color11;
    margin-bottom: 1rem;
  }
  form{
    margin-bottom: 3.5rem;
    input{
      height: 5rem;
      width: 45rem;
      padding-left: 2rem;
      padding-right: 5rem;
      border: .1rem solid $color9;
      font-size: 1.6rem;
      font-weight: 600;
      color: $color16;
    }
    button[type="submit"]{
      border: none;
      background: transparent;
      color: var(--color1);
      font-size: 2.5rem;
      margin-left: -5rem;
      cursor: pointer;
      &:hover{
        color: var(--color2);
      }
    }
  }
  .depot_modal_c{
    @include flex_between;
    padding-top: .8rem;
    padding-bottom: .8rem;
    border-bottom: .1rem solid $color9;
    .depot_modal_name_c{
      display: flex;
      align-items: center;
      width: 45rem;
      font-weight: 600;
      .icon-map-marker{
        font-size: 2rem;
        color: var(--color1);
        margin-right: 1rem;
      }
      .depot_modal_name{
        color: var(--color1);
        font-weight: 700;
      }
    }
  }
  .stock_block .stock .highlight{
    margin-bottom: 0;
  }
}

/* Page panier */
.modal_panier{
  .modal_panier_product_line{
    display: flex;
    align-items: center;
    padding: 2rem;
    border-bottom: .1rem solid $color6;
    font-weight: 600;
    .modal_panier_img_c{
      width: 9rem;
      min-width: 9rem;
      height: 9rem;
      margin-right: 1.5rem;
      text-align: center;
      img{
        max-height: 100%;
      }
    }
    .modal_panier_name{
      font-size: 1.6rem;
    }
    .modal_panier_prix{
      color: $color11;
      font-weight: 700;
    }
    .modal_panier_quantity_c{
      display: flex;
      font-size: 1.2rem;
      div + div{
        margin-left: 3rem;
      }
    }
  }
  .modal_panier_total{
    font-size: 1.8rem;
    font-weight: 700;
    color: $color11;
    margin-left: 8rem;
    margin-top: 2rem;
    .highlight{
      font-size: 2.4rem;
      color: var(--color1);
      margin-left: 2rem;
    }
  }
  .btn_c{
    margin: 2rem;
  }
}

#content_modal_lateral_panier{
  max-height: calc(100vh - 20rem);
  overflow-y: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }
}

@media (max-width: $breakpoint-lg) {
  .modal_dispo{
    width: 82rem;
  }
}

@media (max-width: $breakpoint-md) {
  .modal_dispo{
    width: 60rem;
    .depot_modal_c .depot_modal_name_c{
      width: 20rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .modal_lateral{
    width: 45rem;
  }
  .modal_dispo{
    .modal-lateral-content{
      padding: 1rem;
    }
    .depot_modal_c{
      flex-wrap: wrap;
      .depot_modal_name_c{
        width: 100%;
      }
    }
    form input{
      width: 100%;
    }
  }
  .modal_panier{
    .modal_panier_total{
      margin-left: 2rem;
    }
    .modal_panier_product_line{
      padding: 1rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .modal_lateral {
    width: calc(100% - 8rem);
  }
  .modal_dispo{
    .h3{
      height: 4.8rem;
      font-size: 1.4rem;
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .pres_product{
      flex-wrap: wrap;
      margin-bottom: 1rem;
      .modal_img_product_c{
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 1rem;
      }
      .product_modal_name{
        font-size: 1.6rem;
      }
    }
    .depot_modal_c{
      .depot_modal_name_c{
        font-size: 1.4rem;
        .icon-map-marker {
          margin-right: .5rem;
        }
      }
      .button{
        margin-top: .5rem;
      }
    }
  }
}