.newsletter{
  margin-top: 6rem;
  background-color: $color7;
  padding: 1.8rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content{
    max-width: 47rem;
  }
  input[type="text"]{
    width: 40rem;
    height: 5rem;
    border-color: transparent;
    padding-left: 1rem;
    margin-right: 2rem;
  }
  .button[type="submit"]{
    padding: 1.8rem 8.2rem;
  }
}

@media (max-width: $breakpoint-xl) {
  .newsletter{
    padding: 1.8rem;
    .content{
      max-width: 36rem;
    }
    .button[type="submit"]{
      padding: 1.8rem;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .newsletter {
    margin-top: 4rem;
    .content{
      display: none;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .newsletter input[type="text"]{
    width: 35rem;
  }
}

@media (max-width: $breakpoint-sm) {
  .newsletter {
    flex-wrap: wrap;
    .h3{
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .newsletter{
    > div{
      width: 100%;
    }
    input[type="text"] {
      width: 100%;
    }
    .button[type="submit"]{
      display: block;
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}