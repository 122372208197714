@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans Regular'), local('OpenSans-Regular'),
  url('../fonts/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans SemiBold'), local('OpenSans-SemiBold'),
  url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
  url('../fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans Bold'), local('OpenSans-Bold'),
  url('../fonts/OpenSans-Bold.woff2') format('woff2'),
  url('../fonts/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}