h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.text,
.t1, .t2, .t3,
.title,
.desc,
.titre,
.stitre,
.sstitre
.news_content {
  font-family: "Open Sans", sans-serif;
  word-wrap: normal;
  hyphens: none;
  line-height: 1.3;
  color: var(--color1);
}

h1,
.h1,
.titre
{
  font-size: 4.2rem;
  font-weight: 700;
  letter-spacing: .08rem;
  margin: 1rem 0 0.5rem;
  text-transform: uppercase;
  text-align: center;
}

.title-underline{
  margin-top: 5.5rem;
  span{
    padding: 0 5.5rem;
    background-color: #fff;
  }
  &::after{
    content: "";
    display: block;
    height: .1rem;
    background-color: #999;
    background-color: var(--color1);
    margin-top: -.8rem;
  }
}

.title-red-underline{
  &::after{
    content: "";
    display: block;
    height: .4rem;
    width: 9rem;
    background-color: var(--color2);
    margin-top: 1.2rem;
  }

}

h2,
.h2,
.stitre
{
  font-size: 3.2rem;
  margin: 0.6em 0;
  font-weight: 600;
}

h3,
.h3,
.sstitre {
  font-size: 2.4rem;
  text-transform: uppercase;
  margin: 0.6rem 0;
  font-weight: 700;
}

h4,
.h4,
.ssstitre
{
  font-size: 2rem;
  margin: 0.6rem 0;
  font-weight : 600;
  text-align: center;
}

@media (max-width: $breakpoint-md) {
  body{
    font-size: 1.5rem;
  }

  .h1,
  .titre,
  h1 {
    font-size: 3rem
  }

  .h2,
  .stitre,
  h2 {
    font-size: 2.2rem;
    &::after {
      margin-top: 10px;
    }

  }

  .h3,
  .sstitre,
  h3 {
    font-size: 2rem
  }

  .h4,
  .ssstitre,
  h4 {
    font-size: 2rem
  }
}

@media (max-width: $breakpoint-xs) {
  .h1{
    font-size: 2.5rem;
  }
  .title-underline{
    margin-top: 2.5rem;
    span{
      padding: 0 1.5rem;
    }
  }
}