.products_list_col .products_list_line{
  flex-wrap: wrap;
  .card_product{
    width: 100%;
    .infos_product{
      display: flex;
      align-items: center;
      font-weight: 600;
      .img_block{
        margin: 0;
        max-width: 8rem;
        img{
          object-fit: contain;
        }
      }
      .product_name{
        padding-left: .8rem;
        line-height: 1.2;
        margin-right: auto;
      }
      .img-campagne{
        width: 4rem;
      }
      .stock{
        margin-left: 1rem;
        margin-right: 1rem;
      }
      .price{
        width: 9rem;
        text-align: right;
      }
      .add_cart_catalogue .button{
        padding: 8px 16px;
        .icon{
          font-size: 2.5rem;
          margin-right: .6rem;
        }
      }
    }
    &.product_declinaison .infos_product .product_name{
      width: calc(100% - 35rem);
    }
  }
  .grey-sep-full{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: $breakpoint-md) {
  .products_list_line .card_product .infos_product{
    .product_name,
    form {
      width: 100%;
    }
    .product_name{
      padding-left: 0;
    }
    .stock{
      margin-bottom: 1.2rem;
    }
    .add_cart_catalogue{
      text-align: center;
      width: 100%;
      .button {
        .icon{
          margin-right: 0;
        }
        .texte{
          display: none;
        }
      }
    }
  }
  .products_list_line .card_product.product_declinaison .infos_product .product_name {
    width: 100%;
  }
}