/* Mef Validation form */
.valid-form{
  input[type="email"],
  input[type="text"],
  input[type="password"],
  input[type="date"],
  textarea,
  select{
    display: block;
    border: .1rem solid #dee2e6;
    padding-left: 1.2rem;
    &:focus{
      border-color: #a4afba;
      outline: none;
      box-shadow: transparent;
    }
    &.valid{
      box-shadow:  0 0 .15rem .1rem green;
    }
    &.invalid{
      box-shadow:  0 0 .15rem .1rem #781629;
    }
  }

  input[type="checkbox"] ~ label{
    display: inline;
    margin-left: .8rem;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 0;
  }

  input[type="radio"],
  input[type="radio"] + label {
    cursor: pointer;
  }

  input[type="file"]{
    max-width: 100%;
  }

  textarea{
    width: 100%;
    resize: none;
    height: 15rem;
  }

  .error_form,
  em.valid,
  em.invalid{
    display: inline-block;
    margin-top: .4rem;
    padding-left: 1.2rem;
    font-size: 1.2rem;
    font-style: italic;
    line-height: 1.1;
  }

  .error_form,
  em.invalid {
    color: #781629;
  }

  em.invalid + em.valid{
    display: none;
  }

  .checkbox_container{
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    margin-top: 1rem;
    input{
      cursor: pointer;
    }
    em{
      position: absolute;
      bottom: .5rem;
      left: 0;
    }
    input[type="file"] ~ label{
      margin-bottom: 1.5rem;
    }
    &.invalid{
      input{
        margin-bottom: 2rem;
      }
      label{
        color: #781629;
        margin-bottom: 2rem;
      }
    }
  }
  .file_container{
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    label{
      display: block;
      font-weight: 700;
    }
  }
}

/* Focus effect sur les inputs */
.custom_form{
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"] {
    display: block;
    height: 5.5rem;
    width: 100%;
    border: .1rem solid #bbb;
    padding: 1.5rem 1.5rem .7rem 1.2rem;
    z-index: 2;
    &:disabled{
      border-color: #eee;
    }
  }
  input[type="date"] {
    background-color: white;
    padding: 1.5rem 1.5rem 1.5rem 1.2rem;
  }
  .input-effect{
    max-width: 39rem;
    position: relative;
    text-align: left;
    margin-left: auto;
    margin-bottom: 2rem;
    margin-right: auto;
    .icon{
      margin-right: .6rem;
    }
  }
  .focus-effect{
    padding: .7rem 1.5rem;
    border: .1rem solid #ccc;
    position: relative;
    background: transparent;
  }
  .focus-effect ~ label{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 5.5rem;
    width: 100%;
    color: #888;
    border-radius: .4rem;
    background-color: #fff;
    margin-bottom: 0;
    padding-left: 1.2rem;
    padding-top: 1.6rem;
    transition: all .2s linear;
    z-index: 0;
    letter-spacing: .5px;
  }
  .focus-effect:focus ~ label,
  .has-content.focus-effect ~ label{
    top: 0;
    font-size: 11px;
    color: #888;
    padding-top: .2rem;
    transition: all .2s linear;
  }
  .form_radio_container{
    margin-bottom: 1.5rem;
    input ~ input{
      margin-left: 2rem;
    }
  }
  .form_checkbox_container{
    input{
      margin-right: 1.2rem;
    }
  }
  .backlink.button-filed{
    @include button-filed(var(--color1));
    & + .button{
      margin-left: 1.5rem;
    }
  }
}

.form_control
{
  .custom-select{
    height: 5.5rem;
  }

  .input-group-text{
    display: flex;
    height: 5.5rem;
  }
}

.custom_form .focus-effect:disabled ~ label{
  background-color: $color7;
}

select{
  -webkit-appearance: none;
}

.kcustom-select{
  select {
    // A reset of styles, including removing the default dropdown arrow
    color: #fff;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    &::-ms-expand {
      display: none;
    }
    > option{
        padding-inline: 0;
        color: var(--color1);
    }
  }
  //New style
  position: relative;
  display: flex;
  width: 17rem;
  background-color: var(--color1);
  cursor: pointer;

  &::after{
    content: "\EA04";
    font-family: kfont;
    position: absolute;
    top: .6rem;
    right: 1.3rem;
    display: block;
    font-size: .9rem;
    color: #fff;
    pointer-events: none;
  }
}

.file_container{
  input{
    display: none;
  }
  label.button{
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    margin-bottom: 0;
    height: 5rem;
    .icon{
      font-size: 2rem;
      margin-right: 2rem;
    }
    &:hover{
      color: var(--color1);
      background-color: #fff;
    }
  }
  + .file_container{
    margin-left: 2rem;
  }
}
.custom-select-rounded{
  &::after {
    content: "\EA12";
    top: .9rem;
  }
  font-size: 1.2rem;
  border-radius: 2rem;
  select{
    padding: .5rem 1.3rem .4rem;
  }
}

/**
 * Bloc formulaire choix quantité
 */
.block_quantity{
  width: 19rem;
  .qt_block{
    display: flex;
    align-items: flex-start;
    margin-top: .8rem;
    margin-bottom: .8rem;
    .qt_manage,
    .inpQty{
      display: inline-block;
      height: 4rem;
      border: .1rem solid $color9;
      text-align: center;
      color: $color16;
      padding: 0;
      margin-top: 1px;
    }
    .qt_manage{
      width: 3rem;
      font-size: 2.4rem;
      transition: color .2s, background-color .2s;
      cursor: pointer;
      &:hover,
      &:focus{
        color: #fff;
        background-color: var(--color1);
      }
    }
    .inpQty{
      width: 5.5rem;
      margin-left: -.1rem;
      margin-right: -.1rem;
      z-index: 1;
    }
    &.qt_disabled{
      .qt_manage,
      .inpQty{
        cursor: not-allowed;
        color: #eee;
        background-color: #ccc;
      }
    }
  }
}

/////////////////////////////
//// select personnalisé ////
/////////////////////////////
.select-custom {
  position: relative;
  &::after {
    content: '\EA12';
    font-family: 'kfont';
    display: block;
    position: absolute;
    top: 2rem;
    right: 1.2rem;
    pointer-events: none;
  }
  select {
    background-color: #fff;
    padding: 1rem 4rem 1rem 1.4rem;
    appearance: none;
    cursor: pointer;
  }
}

/* Show MDP */
.formline-mdp {
  position: relative;
  .btn-mdp {
    cursor: pointer;
    position: absolute;
    z-index: 5;
    top: 1.8rem;
    right: .5rem;
    font-size: 2rem;
    display: flex;
    &.icon-eye-show {
      color: $color19;
    }
  }
}