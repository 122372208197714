
/* Button */
#body_cms .content .button,
.button,
#body_cms .content .js_lien-cache.button,
.js_lien-cache.button,
#body_cms .content .lien-off.button,
.lien-off.button {
  display : inline-block;
  color: #fff;
  background-color: #999;
  border: 1px solid #999;
  background-color: var(--color1);
  border: 1px solid var(--color1);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .15rem;
  padding: 12px 35px;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: all .3s ease-in;

  &:not([href]){
    /*cursor: default;*/
  }

  &:not([href]):hover,
  &:not([href]):focus {
    color: #fff;
    background-color: #999;
    border-color: #999;
    background-color: var(--color1);
    border-color: var(--color1);
  }

  &.button-cta{
    cursor: pointer;
  }

  &:hover,
  &.button-cta:hover,
  &:focus,
  &.button-cta:focus {
    color: var(--color1);
    opacity: 1;
    background-color: #fff;
    border-color: var(--color1);
  }
  &.button-disable,
  &.button-disable:hover{
    border-color: $color12;
    background-color: $color12;
    color: #fff;
    cursor: not-allowed;
  }
}

.button.button-custom {
  position: relative;
  display: inline-block;
  text-align: center;
  letter-spacing: 0.05em;
  background-color: #d79e85;
  color: #222429;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;

  &::before{
    display: block;
    content: "";
    position: absolute;
    right: -15px;
    top: 0;
    width: 26px;
    height: 100%;
    background: #2e5f74;
    opacity: 0.2;
    z-index: 0;
    transform: skewX(-22deg);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
}


.button.button-vert { background-color:#6cc332; color:#fff; border:1px solid #6cc332;}
.button.button-rouge { background-color:#dd3b59; color:#fff; border:1px solid #dd3b59; }
.button.button-orange { background-color:#ED8200; color:#fff; border:1px solid #ED8200; }
.button.button-violet { background-color:#92278f; color:#fff; border:1px solid #92278f; }
.button.button-blanc { background-color:transparent; color:#6e9619; border:1px solid #6e9619; }
.button.button-bleu { background-color:#388ed7; color:#fff; border:1px solid #388ed7; }

.button.button-custom:hover,
.button.button-custom:focus {
  background: #d79e85;
  color: #fff;
  &::before {
    opacity: 1;
    right: 0;
    width: 100%;
    transform: skewX(0deg);
    background: #2e5f74;
  }

  span{
    position: relative;
    z-index: 1;
    color: #FFFFFF;
  }
}

.button.button-vert:hover,
.button.button-vert:focus { background-color:rgba(255,255,255,0.8); color:#6cc332; border:1px solid #6cc332; }

.button.button-rouge:hover,
.button.button-rouge:focus { background-color:rgba(255,255,255,0.8); color:#dd3b59; border:1px solid #dd3b59; }

.button.button-orange:hover,
.button.button-orange:focus { background-color:#fff; color:#ED8200; border:1px solid #ED8200; }

.button.button-violet:hover,
.button.button-violet:focus { background-color:#fff; color:#92278f; border:1px solid #92278f; }

.button.button-blanc:hover,
.button.button-blanc:focus { background-color:#3d3d3d; color:#fff; border:1px solid #3d3d3d; }

.button.button-bleu:hover,
.button.button-bleu:focus { background-color:rgba(255,255,255,0.8); color:#388ed7; border:1px solid #388ed7; }

.button.button-gris,
.button.button-gris:focus {
  background-color: #868582;
  color: #fff;
  border: 1px solid #868582;
  &:hover,
  &:focus{
    color: #868582;
    background-color: #fff;
    border: 1px solid #868582;
  }
}

.button.btn-small {
  font-size: 1rem;
  padding: .5rem 1rem; }

.btn_center_c{
  text-align: center;
  margin-top: 3rem;
}

.pointer{
  cursor: pointer;
}

.tooltip {
  z-index: 999999!important;
  &.tooltip-available > .tooltip-inner{
    background-color: #7ebc00;
  }
  &.tooltip-not-available > .tooltip-inner{
    background-color: #f59a10;
  }
  &.tooltip-outstock > .tooltip-inner{
    background-color: #e2001a;
  }
}
.tooltip .arrow::before {
  &.icon-available{
    border-top-color: #7ebc00;
  }
  &.icon-not-available{
    border-top-color: #f59a10;
  }
  &.icon-outstock{
    border-top-color: #e2001a;
  }
}

.tooltip > .tooltip-inner{
  background-color: var(--color1);
  font-size: 1rem;
}
.tooltip .arrow::before {
  border-top-color: var(--color1)!important;
}

@mixin button-filed($color-filed){
  border: .1rem solid $color-filed;
  background-color: #fff;
  color: $color-filed;
  font-size: 1.3rem;
  font-weight: 600;
  padding: .9rem 3rem;
  opacity: 1;
  .icon{
    font-size: 2.5rem;
    margin-right: .8rem;
  }
  &:hover{
    background-color: $color-filed;
    color: #fff;
    text-decoration: none;
  }
  &.favoris,
  &.favoris:hover{
    color: $color-filed;
    background-color: #fff;
    text-decoration: none;
  }
}

@media (max-width: $breakpoint-lg) {
  #body_cms .content .button,
  .button,
  .button-filed{
    padding: 12px 15px;
  }
}