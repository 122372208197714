#chemin {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  p{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem
  }
  .breadcrumb_sep{
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
}


#chemin,
#chemin a {
  color: $color16;
  line-height: 1.2;
  text-align: center;
}

#chemin a.bread_link:hover,
#chemin a:hover {
  color: var(--color1);
}

@media (max-width: $breakpoint-xs) {
  #chemin{
    height: auto;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #chemin,
  #chemin a {
    text-align: left;
  }
}