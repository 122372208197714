.rassurances{
  text-align: center;
  color: #fff;
  background-color: #999;
  background-color: var(--color1);
  padding: 3.2rem 8rem;
  margin-top: 6rem;
  .rassurances_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rassurances_card{
      width: calc(25% - 1.5rem);
      .icon{
        font-size: 4.2rem;
        &::after{
          content: "";
          display: block;
          width: 2.8rem;
          height: .3rem;
          background-color: var(--color2);
          margin-left: auto;
          margin-right: auto;
          margin-top: -.5rem;
        }
      }
      .content{
        font-size: 1.6rem;
        font-weight: 700;
        max-width: 20rem;
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .rassurances{
    padding: 2rem 1rem;
    margin-top: 2rem;
    .rassurances_list .rassurances_card .content{
      font-size: 1.4rem;
      height: 6.4rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .rassurances .rassurances_list{
    flex-wrap: wrap;
    .rassurances_card{
      width: calc(50% - 1rem);
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .rassurances .rassurances_list .rassurances_card{
    width: 100%;
  }
}