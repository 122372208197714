.page_contact{
  .highlight{
    font-size: 1.8rem;
    font-weight: 700;
  }
  .h3 .icon{
    font-size: 4rem;
    margin-right: 1.2rem;
  }
  .coordonnees_contact{
    background-color: $color8;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .custom_form{
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
    textarea{
      resize: none;
    }
  }
  .home_adress{
    margin-top: 3rem;
  }
  .label-title{
    font-size: 1.8rem;
  }
}