#map {
  height: 70rem
}

#map .popmap_info {
  color: #000
}

#map .popmap_info .t1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: .5rem;
  color: #a72430
}

#map .popmap_info p {
  margin-top: .5rem;
  margin-bottom: 0
}