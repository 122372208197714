#gotop{
  position: fixed;
  bottom: 10rem;
  right: 4rem;
  border-radius: 50%;
  color: #fff;
  background-color: $color16;
  opacity: .85;
  width: 4rem;
  height: 4rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  cursor: pointer;
  font-size: 1.8rem;
  transition: all .3s;
  &:hover{
    background-color: #999;
    background-color: var(--color1);
  }
}

@media (max-width: $breakpoint-md) {
  #gotop{
    bottom: 5rem;
    right: 2rem;
  }
}

@media (max-width: $breakpoint-xs) {
  #gotop{
    bottom: 2rem;
    right: 1rem;
  }
}