/* Bootstrap 4
.col- (extra small devices - screen width less than 576px)
.col-sm- (small devices - screen width equal to or greater than 576px)
.col-md- (medium devices - screen width equal to or greater than 768px)
.col-lg- (large devices - screen width equal to or greater than 992px)
.col-xl- (xlarge devices - screen width equal to or greater than 1200px)
*/

$breakpoint-xs: 575.98px;
$breakpoint-sm: 767.98px;
$breakpoint-md: 991.98px;
$breakpoint-lg: 1199.98px;
$breakpoint-xl: 1399.98px;

.mobile_only{
  display: none;
}

@media (max-width: $breakpoint-sm) {
  .mobile_only{
    display: block;
  }
}