.page_catalogue {
  .header_page {
    height: 16.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;

    .h3 {
      color: #fff;
      font-size: 2.9rem;
      font-weight: 400;
    }
  }

  #chemin {
    margin-bottom: 3.5rem;
  }
}

/* Colonne de gauche */

.btn_supprimer_filtre {
  cursor: pointer;
  margin-right: 5px;
}

#recapitulatif_filtres {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.label_filtre_selectionne {
  display: inline-flex;
  align-items: flex-start;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 2px;
  padding: 0 16px;
  height: 32px;
  border-radius: 16px;
  background-color: #ecedf0;
  border: 1px solid transparent;
  color: #0c193a;
  line-height: 32px;
  font-weight: 600;
  outline: none;
  transition: .2s ease;
}

.input_filtre_selectionne {
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
  outline: none;
  border: 1px solid #b1b5c0;
}

.category_list_filtre {
  margin-bottom: 3rem;

  .h2 {
    color: $color11;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;

    &::after {
      content: "";
      display: block;
      height: .1rem;
      width: 100%;
      background-color: $color11;
      margin-top: 1.6rem;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 1rem;
    margin-bottom: 0;
  }

  .level1 {
    .filter_libelle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      cursor: pointer;

      .filter_libelle_link {
        color: $color11;
        cursor: pointer;

        &.current,
        &:hover {
          color: var(--color1);
        }
      }

      .collapse_block {
        .see_more {
          display: block;
          cursor: pointer;
        }

        .see_more_item {
          display: block;
        }

        .see_less_item {
          display: none;
        }
      }

    }

    hr {
      display: block;
      background-color: $color6;
      height: .1rem;
      width: calc(100% + 1rem);
      margin-left: -1rem;
      margin-top: 0;
      margin-bottom: 0;
      border: none;
    }

    > li {
      position: relative;
      font-weight: 600;
      color: #302D29;
      margin-top: 0;
    }

    .filtre_checkbox_c {
      input {
        cursor: pointer;
        margin-right: .8rem;
        color: $color12;
      }

      label {
        cursor: pointer;

        &:hover {
          color: var(--color1);
        }
      }

      input:checked + label {
        color: var(--color1);
      }
    }
  }

  .level2 {
    height: 0;
    overflow: hidden;

    & > li {
      margin-top: .8rem;
      margin-bottom: .8rem;

      a {
        color: $color12;

        &.current,
        &:hover {
          color: var(--color1);
        }
      }
    }
  }

  .level1 > .active {
    .filter_libelle {
      .see_more_item {
        display: none;
      }

      .see_less_item {
        display: block;
      }
    }

    .level2 {
      height: auto;
      max-height: 23rem;
      overflow: auto;
    }
  }
}

.univers_list_filtre {
  a {
    color: $color11;
    text-transform: uppercase;
    font-weight: 600;

    &.current,
    &:hover {
      color: var(--color1);
    }
  }
}

.filtres_col_c.sticky_block {
  margin-bottom: 17.5rem;
}

.see_more {
  display: block;

  & > span {
    font-size: 3rem;
    color: $color16;
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
  }
}

.filtres_mobile_c {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;

  a {
    padding: 1.5rem 4rem;
    border-radius: 4px;
    border-color: #fff;
  }
}

.toggle-switch {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: left;
  gap: 1rem;
  padding-top: 1rem;

  &:has(input:checked) {
    div {
      background-color: var(--color1);

      &::before {
        left: 2.3rem;
      }
    }
  }

  label {
    font-weight: 500;
    color: $color11;
    margin-bottom: 0;
  }

  div {
    position: relative;
    display: block;
    background-color: $color9;
    border-radius: 1.5rem;
    min-width: 4.4rem;
    max-width: 4.4rem;
    height: 2.4rem;
    transition: all .3s ease-in-out;

    &:hover,
    &:focus {
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: .3rem;
      transform: translateY(-50%);
      background-color: white;
      border-radius: 50%;
      width: 1.8rem;
      height: 1.8rem;
      pointer-events: none;
      transition: all .3s ease-in-out;
    }

    input {
      appearance: none;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

/* Colonne de droite */
.products_list_col {
  .header_products_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color7;
    padding: 1.2rem 1rem 1.2rem 3.3rem;
    margin-bottom: 4rem;

    .nb_products {
      font-weight: 600;
    }

    .orga_products {
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 2.4rem;

      label {
        font-weight: 600;
        margin-bottom: 0;
      }

      .select_c {
        position: relative;
        cursor: pointer;
        padding-right: 1rem;

        select {
          color: $color16;
          background-color: $color7;
          border: none;
          appearance: none;
          outline: 0;
          cursor: pointer;
          padding: 0 1.8rem 0 .5rem;
        }

        &::after {
          content: "\EA15";
          display: block;
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          font-family: kfont;
          font-size: 1rem;
          pointer-events: none;
        }
      }

      .icon {
        display: inline-flex;
        font-size: 2.8rem;
        line-height: 1;
        color: $color16;

        &.current {
          color: var(--color1);
        }

        + .icon {
          margin-left: .8rem;
        }
      }
    }
  }

  &.grid-list {
    .products_list_line {
      display: none;
    }
  }

  &.table-list {
    .products_list_line {
      display: flex;
    }

    .products_list_card {
      display: none;
    }
  }
}

/* Pagination */
.pagination_catalogue {
  margin-top: 2rem;
  text-align: center;

  .count {
    font-size: 1.8rem;
    color: #707070;
  }

  .progress_bar_catalogue {
    position: relative;
    height: .3rem;
    width: 23rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    border: .1rem solid #707070;

    .progress_status {
      position: absolute;
      top: -.1rem;
      left: -.1rem;
      background-color: #999;
      background-color: var(--color1);
      height: .3rem;
    }
  }

  .button {
    padding: 18px 58px;
    margin-top: 3.5rem;
  }
}

@media (max-width: $breakpoint-md) {
  .products_list_col .header_products_list {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-top: 2.2rem;
    padding-left: 1.5rem;

    .orga_products {
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0 1.6rem;
      width: 100%;

      .catalogue_disposition_produits {
        margin-top: 1.6rem;
        margin-left: auto;
      }
    }
  }
  .products_list_col .products_list_line .card_product .infos_product {
    flex-wrap: wrap;

    .product_name {
      width: 100%;
      margin-bottom: 1.2rem;
    }

    .price {
      padding-left: .8rem;
      text-align: left;
    }
  }
  .page_catalogue {
    #chemin {
      margin-bottom: 1.5rem;
    }

    .header_page {
      height: 8rem;

      .h3 {
        font-size: 2.2rem;
      }
    }
  }

}

@media (max-width: $breakpoint-sm) {
  .filtres_col_c {
    display: none;
  }
  .popin_filtres .fancybox-slide {
    padding: 0;
  }
  .category_list_filtre {
    padding: 1rem;
    width: 100vw;
    height: 100vh;
    margin-bottom: 0;

    .h2 {
      text-align: center;
      color: var(--color1);
    }

    .level1 .filtre_checkbox_c label {
      margin-bottom: 0;
    }
  }
}