@charset "UTF-8";
/* Slider */
.slick-slider{
    list-style-type: none;
    padding-left: 0;
    line-height: 1;
    &.slick-dotted{
        margin-bottom: 0;
        .slick-dots{
            bottom: 3.8rem;
            transition: background-color .3s;
            li{
                height: 1.6rem;
                width: 1.6rem;
                button{
                    height: 1.6rem;
                    width: 1.6rem;
                    border-radius: 50%;
                    background-color: #fff;
                    opacity: .6;
                    &::before{
                        content: none;
                    }
                }
            }
            .slick-active{
                button{
                    opacity: 1;
                }
            }
        }
    }
}

.islide_content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fancybox-thumbs__list a::before {
  border-color: var(--color1);
}

@media (max-width: $breakpoint-sm) {
  ul.slick-slider.slick-dotted .slick-dots{
    bottom: 1.8rem;
  }
}