@mixin cover_img{
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

@mixin flex_center{
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex_between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin absolute_container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Limite le nombre de ligne du champ, NE PAS METTRE DE HAUTEUR */
@mixin line-clamp($nb-line:3) {
  display: -webkit-box;
  -webkit-line-clamp: $nb-line;
  line-clamp: $nb-line;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}

@mixin sep($couleur:$color6, $hauteur:.1rem, $largeur:100%) {
  display: block;
  width: $largeur;
  height: $hauteur;
  margin: 1.5rem auto;
  background-color: $couleur;
}

/* Effets css */

@mixin box_shadow{
  box-shadow: 0 .6rem .8rem 0 #cfcfcf;
}

@mixin box_shadow_light{
  box-shadow: 0 .3rem .4rem 0 #cfcfcf;
}

/* Scrollbar */
@mixin scrollbar($scrollbar-color:rgba(0,0,0,0.2), $scrollbar-color-bg:transparent, $scrollbar-width:.8rem, $max-height:none) {

  overflow-y: auto;
  max-height: $max-height;

  /* Works on Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-color $scrollbar-color-bg;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-color-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    /*border-radius: 50%;*/
    border: 0 solid $scrollbar-color-bg;
  }
}


@mixin icon_rounded($size){
  display: inline-block;
  text-align: center;
  font-size: 2rem;
  color: #fff;
  border-radius: 50%;
  line-height: 1.5;
  font-weight: 700;
  width: $size;
  height: $size;
  background-color: red;
}

/* chemin img */
$base-img-url: "../img/theme";

// Permet de garder le ratio.
// Pour changer le ratio (carré par défaut), ajouter sur l'élément container avec la classe ratio un style="--ratio: 16/9"
.ratio {
  position: relative;
}
// Permet de garder le ratio.
// Pour changer le ratio (carré par défaut), ajouter sur l'élément container avec la classe ratio un style="--ratio: 16/9"
.ratio {
  position: relative;
}

.ratio::before {
  content: '';
  display: block;
  padding-bottom: calc(100% / (var(--ratio, calc(1))));
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
}
.ratio.contain > * {
  object-fit: contain;
  background-size: contain;
}
