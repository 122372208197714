#footer{
  display: flex;
  justify-content: space-around;
  border-top: 1px solid $color9;
  border-bottom: 1px solid $color9;
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: $color11;
  a,
  .js_lien-cache,
  .lien-off {
    color: $color11;
    &:hover{
      color: var(--color1);
    }
  }
  .logo_footer{
    margin-bottom: 3rem;
  }
  .reseau_circle{
    width: 3.5rem;
    height: 3.5rem;
    line-height: 4rem;
    text-align: center;
    background-color: #999;
    background-color: var(--color1);
    border-radius: 50%;
    border: .1rem solid #999;
    border: .1rem solid var(--color1);
    color: #fff;
    font-size: 2rem;
    &:hover{
      color: var(--color1);
      background-color: #fff;
    }
    + .reseau_circle{
      margin-left: 1rem;
    }
  }
  .coord_footer .coord_footer_line{
      display: flex;
      align-items: center;
    + .coord_footer_line{
      margin-top: 3rem;
    }
    .icon{
      color: var(--color1);
      font-size: 2.5rem;
      margin-right: 1.4rem;
      line-height: 1;
    }
  }
  .status{
    text-transform: uppercase;
    color: #87C15B;
    &.status-closed{
      color: #b20c0c;
    }
  }
  .h3{
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  .menu_footer .h3 a,
  .menu_footer .h3 .js_lien-cache,
  .menu_footer .h3 .lien-off {
    color: var(--color1);
    margin-bottom: 0;
    &:hover{
      color: $color11;
    }
  }
  .menu_footer a,
  .menu_footer .js_lien-cache,
  .menu_footer .lien-off {
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.sub_footer{
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  a,
  .js_lien-cache,
  .lien-off {
    color: #777;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    &:hover{
      color: var(--color1);
    }
  }
}

/* Signature Kyxar */
.kyxar {
  position: absolute;
  z-index: 9;
  white-space: nowrap;
  right: 1rem;
  margin-top: -5rem;
  padding: 0;
  line-height: 2.5rem;
  font-size: 1rem;
}

.kyxar a,
.kyxar .js_lien-cache,
.kyxar .lien-off {
  color: #999;
  font-size: 1rem;
  text-decoration: none;
  letter-spacing: 1px;
  position: relative
}

.kyxar a:hover,
.kyxar .js_lien-cache:hover,
.kyxar .lien-off:hover {
  color: var(--color1);
}

.kyxar a .none,
.kyxar .js_lien-cache .none,
.kyxar .lien-off .none {
  position: absolute;
  bottom: 3.8rem;
  right: 2.8rem;
  height: 0;
  overflow: hidden;
  color: var(--color2);
}

.kyxar a:hover .none,
.kyxar .js_lien-cache:hover .none,
.kyxar .lien-off:hover .none {
  overflow: visible
}

.kyxar a .none span,
.kyxar .js_lien-cache .none span,
.kyxar .lien-off .none span {
  display: inline-block;
  background-color: #fff;
  border-radius: .3rem;
  padding: .5rem 1rem;
  line-height: 1;
  font-weight: 300;
  font-size: 1rem
}

@media (max-width: $breakpoint-md) {
  #footer{
    flex-wrap: wrap;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .logo_footer_c{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .coord_footer{
      margin-right: 1rem;
    }
    .sub_footer{
      margin-bottom: 2rem;
    }
  }
  .kyxar{
    margin-top: -3.5rem;
  }
}

@media (max-width: $breakpoint-sm) {
  #footer .logo_footer{
    margin-bottom: 2rem;
    img {
      width: 16rem;
      height: 7rem;
      object-fit: contain;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  #footer{
    > div{
      width: 100%;
    }
    .coord_footer{
      margin-bottom: 2rem;
      .coord_footer_line + .coord_footer_line{
        margin-top: 1rem;
      }
    }
    .menu_footer{
      margin-top: 2rem;
      text-align: center;
    }
  }
  .sub_footer a,
  .sub_footer .js_lien-cache,
  .sub_footer .lien-off {
    display: block;
  }
}