/* Specifique a la news current */
.bloc_actu{
  margin-bottom: 2rem;
}
.news_content{
  .img_container{
    position: relative;
    .date_news{
      position: absolute;
      top: 0;
      right: 0;
      padding: .6rem 1rem;
      z-index: 1;
      background-color: var(--color1);
      color: #fff;
      font-size: 1.6rem;
    }
  }
  .current_news_text{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    h1{
      margin-bottom: 2rem;
    }
  }
}

.slider-single-news .slick-slide img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slider-nav-news {
  .slick-track {
    margin: 1rem auto;

    .slick-slide {
      margin-right: 1.5rem;

      img {
        object-fit: contain;
        @include box-shadow-light;
        cursor: pointer;
        height: 7.5rem;
        border: .2rem solid transparent;
        padding: .3rem;
      }
    }
  }

  .slick-slide.is-active img {
    border-color: $color8;
  }
}


.page_news .actu_liste{
  flex-wrap: wrap;
}

/* Listing news */
.liste_articles .title-underline{
  font-size: 1.8rem;
}

