/* Global */
.register_page,
.page_mes-commandes {
  .button-filed {
    @include button-filed(var(--color1));
  }
}

/* Ajustement page sur 2 colonnes avec image à gauche */
.main_page {
  &.window_page {
    min-height: 70rem;
  }

  .espace_client_col_left {
    .text_login_c {
      height: 100%;
      @include flex_center;
      background-position: bottom right;
      background-size: cover;

      .text_login_content {
        width: calc(100% - 10rem);
        background-color: rgba(255, 255, 255, .85);
        padding: 4rem;

        img {
          max-width: 20rem;
          max-height: 14rem;
          margin-bottom: 2rem;
        }
      }
    }
  }

  .espace_client_col_right {
    @include flex_center;
    width: 100%;
    height: 100%;

    .espace_client_col_right_content {
      text-align: center;
      max-width: 40rem;

      .h2 {
        font-size: 3.2rem;
        font-weight: 700;
        text-transform: initial;
      }
    }
  }
}

/* First Login */
.first_login.main_page .espace_client_col_right_content, .lost_password.main_page .espace_client_col_right_content {
  max-width: 60rem;
  text-align: center;

  .h2 {
    line-height: 1.2;
  }
}

/* Connexion obligatoire */
.auth_b2b {
  .alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .login_page,
  .register_page,
  .lost_password,
  .reset_password,
  .first_login {
    height: 100vh;
  }
}

/* Login */
.login_page {
  position: relative;
  z-index: 1;

  .espace_client_col_right_content {
    input {
      margin-bottom: 1.5rem;
    }

    .mdp_oublie {
      margin-top: 1.2rem;

      a {
        display: block;
        color: $color16;
        font-size: 1.6rem;
        font-weight: 400;

        &:hover {
          color: var(--color1);
        }
      }

      .button {
        display: block;
        padding: 12px 50px;
        margin: 1.6rem auto 5rem;
      }
    }

    > .button-filed {
      display: inline-block;
      margin-bottom: 1.5rem;
    }
  }

  .espace_client_inscription {
    p {
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    a:hover {
      color: var(--color2);
    }
  }
}

.check_cgv,.piece_jointes_cgv_info{
  text-align: left;
}
/* Register */
.register_page {
  text-align: center;
  margin-bottom: 3rem;
  padding: 3rem;

  .bloc_register {
    background-color: $color8;
    padding: 2rem 3rem 1rem;
    margin-bottom: 3rem;

    .h4 {
      margin-bottom: 3rem;
    }

    p {
      font-weight: 700;
    }

    .liste-register {
      padding-left: 0;

      li {
        width: 40rem;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .line_pro {
    display: none;
  }

  input[type="submit"] {
    margin-top: 3rem;
    margin-left: 1.5rem;
  }

  .file_container_c {
    display: flex;
    .pieces_jointes_register{
      margin-top: 10px;
      margin-right: 20px
    }
  }

  .kcustom-select {
    margin-bottom: 2rem;
  }
}

.lowhight {
  font-size: 1.2rem;
  line-height: 1.1;
  text-align: left;
}

.register_page,
#searchDocument {
  .kcustom-select {
    position: relative;
    display: block;
    width: 100%;
    max-width: 39rem;
    text-align: left;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;

    select {
      display: block;
      height: 5.5rem;
      width: 100%;
      color: $color16;
      background-color: #fff;
      border: .1rem solid $color17;
      border-radius: .4rem;
      padding: .7rem 1.5rem .7rem 1.2rem;

      > option {
        color: $color16;
      }
    }

    &::after {
      color: var(--color1);
      top: 2rem;
    }
  }
}

.flex-register {
  gap: 2rem;

  .formline, .kcustom-select {
    width: calc(50% - 1rem);
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }

    .input-effect {
      max-width: unset;
      margin: 0;
    }
  }

  .kcustom-select {
    max-width: unset;
    margin: 0;

    select {
      border-radius: 0;
    }
  }
}

/* Espace personnel Header */
.header_espace_client {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include box_shadow_light;

  .h4 {
    width: calc(20% - .5rem);
    background-color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    margin-right: .5rem;

    a {
      display: block;
      font-size: 1.6rem;
      font-weight: 700;
      text-transform: uppercase;
      padding: 1.5rem .5rem;
      border-bottom: .2rem solid transparent;

      &.current {
        border-bottom: .2rem solid var(--color2);
      }
    }
  }
}

/* Espace personnel Global */
#espaceClient {
  position: relative;
  z-index: 1;
}

.box_espace_client {
  padding: 2rem;
  background-color: $color7;

  .box_espace_client_content {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 2rem 3rem 6rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    @include box_shadow_light;

    .liste_paniers_enregistres{
      .icon-check,.icon-pencil{
        font-size: 1.8rem;
        color: $color16;
        cursor: pointer;
        margin-left: 10px;
        &:hover {
          color: var(--color1);
        }
      }
    }
    .img-liste-achat-c {
      @include flex_center;
      height: 15rem;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 1rem;

      .h3 {
        color: #fff;
      }
    }

    .h3 {
      font-size: 3.8rem;
      text-transform: none;
    }

    .small_c {
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;

      .h4 {
        font-size: 1.6rem;
        font-weight: 700;
        text-align: left;
        margin-top: 2.2rem;
        margin-bottom: 1.5rem;
      }
    }

    .grey_block {
      @include flex_between;
      background-color: $color14;
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding-bottom: 3rem;

      .grey_block_content {
        display: flex;

        .icon {
          color: var(--color1);
          font-size: 3rem;
          margin-right: 1.2rem;
        }

        .h4 {
          color: var(--color1);
          text-align: left;
          font-size: 1.6rem;
          font-weight: 700;
          text-transform: uppercase;
        }

        .grey_block_adresse {
          color: $color11;
          font-size: 1.6rem;
          font-weight: 600;
          margin-top: 2rem;
        }
      }
    }

    .formline .button-cta {
      margin-top: 3rem;
    }

    .recap_article_content {
      .block_panier_desc {
        width: calc(100% - 75rem);
      }

      .icon-noheart {
        font-size: 2.4rem;
        cursor: pointer;

        &:hover {
          color: var(--color1);
        }
      }
    }

    .mode-paiement.paiement-ok {
      display: inline-block;
      color: #fff;
      background-color: var(--color1);
      padding: .8rem;
      margin: 1rem 0;
    }
  }

  .button-cta {
    padding: 1.8rem 2.5rem;
  }
}

/* Espace personnel Home */
#home_backoffice {
  .h2 {
    text-transform: initial;
    font-size: 4.2rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .card_gestion_backoffice_c {
    background-color: $color7;
    padding-top: 6rem;
    margin-left: 0;
    margin-right: 0;

    .card_gestion_backoffice {
      .h3 a {
        display: block;
        text-align: center;
        width: calc(100% - 4rem);
        margin-left: auto;
        margin-right: auto;

        &::before {
          content: "";
          display: block;
          height: .1rem;
          width: 100%;
          background-color: #CDCAC9;
          margin-bottom: 1rem;
        }

        &::after {
          content: "";
          display: block;
          height: .1rem;
          width: 100%;
          background-color: #CDCAC9;
          margin-top: 1rem;
        }

        &:hover {
          color: var(--color2);
        }
      }

      .card_gestion_content {
        @include flex_center;
        margin-top: 2.5rem;
        margin-bottom: 4rem;

        .card_gestion_bribe {
          width: 40rem;
          font-size: 1.8rem;
        }

        .icon {
          font-size: 6rem;
          margin-right: 6rem;

          &:hover {
            color: var(--color2);
          }
        }
      }
    }
  }

  .card_tarif_client {
    margin-top: 1.5rem;
    width: calc(100% / 2 - 3rem);
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    background-color: $color7;
    text-align: center;
    padding: 1.5rem 1.5rem 2.5rem;

    .button-container {
      position: relative;

      .loader {
        position: absolute;
        right: 0;
        top: .6rem;
        border-color: #ccc;
        border-top-color: var(--color1);
      }
    }

    .button {
      @include flex_center;
      width: 100%;
      max-width: 38rem;
      height: 5.2rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.2;

      .icon {
        font-size: 2rem;
        margin-right: 1rem;
        line-height: 0;

        &.icon-get-price {
          font-size: 3rem;
        }
      }

      &:hover {
        cursor: pointer;
        color: var(--color1);
        opacity: 1;
        background-color: #fff;
        border-color: var(--color1);
      }
    }

    .h3 {
      margin-bottom: 2.5rem;

      &::before,
      &::after {
        content: "";
        display: block;
        height: .1rem;
        width: 100%;
        background-color: #cdcac9;
      }

      &::before {
        margin-bottom: 1rem;
      }

      &::after {
        margin-top: 1rem;
      }
    }

    .erreur-demande-tarif {
      color: #e40828;

      .icon {
        font-size: 2rem;
        margin-right: 0.2rem;
      }
    }

    .erreur-dl-tarif {
      font-weight: 700;
      color: var(--color2);
    }
  }
}

.row-espace-client {
  display: flex;
  justify-content: center;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

/* Espace personnel, tableaux */
.table_espace_client {
  width: 100%;
  margin-top: 3rem;

  thead {
    background-color: #efefef;
  }

  tr {
    text-align: center;
    height: 7.3rem;
    border-bottom: .1rem solid $color9;
  }

  th, td {
    text-align: center;
    padding: 1rem .5rem;
  }

  .voir_produits {
    cursor: pointer;

    &:hover {
      color: var(--color1);
    }

    + a {
      margin-left: .8rem;
    }
  }

  .plus_produits {
    .h4 {
      text-align: left;
      margin-bottom: 2rem;
    }
  }

  .detail_produit_c {
    + .detail_produit_c::before {
      content: "";
      display: block;
      height: .1rem;
      width: 75%;
      max-width: 65rem;
      background-color: #ddd;
      margin: 1.5rem auto;
    }

    .detail_produit {
      margin-top: 1rem;
      display: flex;
      justify-content: space-around;

      .detail_produit_nom {
        width: 40rem;
      }

      .detail_produit_prix {
        width: 19rem;
      }

      .detail_produit_stock {
        width: 15rem;
      }
    }
  }

  .icon-cart,
  .icon-telecharger,
  .icon-credit-card2,
  .icon-warning,
  .icon-eye {
    font-size: 2.8rem;
    line-height: 1;
  }

  .icon-pencil {
    font-size: 2.1rem;
    line-height: 1;
  }

  .dl_document {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader {
    margin-left: 0;
    margin-right: 0;
  }

  .alert_product {
    margin-left: .6rem;
  }

  .stock {
    justify-content: center;

    .label {
      min-width: 8rem;
    }
  }

  .icon-supp {
    font-size: 1.8rem;
    color: $color16;

    &:hover {
      color: var(--color1);
    }
  }

  .ligne_demande_paiement.current {
    background-color: #d5d5d5;
  }
}

.box_espace_client .add_cart {
  font-size: 2rem;
  border: .1rem solid var(--color1);
  background-color: var(--color1);
  line-height: 1.7;
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: var(--color1);
    background-color: #fff;
  }
}

/* Espace personnel Mes produits favoris */
.page_mes-produits-favoris {
  .favo-produit-img {
    @include flex_center;
    width: 9rem;
    height: 9rem;

    img {
      max-height: 100%;
    }
  }

  .btn-action-c {
    display: flex;
    justify-content: space-around;
    min-width: 10rem;
  }

  .box_espace_client .box_espace_client_content .recap_article_content {
    min-width: 0;

    .block_panier_desc {
      width: calc(100% - 25rem);
    }
  }

  .recap_liste_c {
    overflow: hidden;

    .grey_block {
      align-items: center;
      justify-content: center;
      height: 10rem;
      padding: 1.5rem 1.5rem 1rem;
      margin-bottom: 0;
      border: .2rem solid transparent;

      .nom-liste-c {
        text-align: center;

        .nom-liste {
          display: block;
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 1;
          text-transform: uppercase;
        }

        .nb-produits-liste {
          color: $color16;

        }
      }

      &:hover {
        border-color: var(--color1);
      }
    }
  }

  .see-more-lists {
    display: none;
    text-align: center;
    margin-top: 2.2rem;
  }
}

.liste-achat-header-liste {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color7;
  padding: 1.2rem 1rem 1.2rem 3.3rem;
  margin-bottom: 1rem;

  .liste_achat_disposition .icon {
    font-size: 2.8rem;
    line-height: 1;
    color: #777;
    margin-right: 1rem;

    &.current {
      color: var(--color1);
    }
  }
}

/* Gestion affichage de la liste bloc-ligne */
.products_bloc_line {
  display: none;
}

.grid-list .products_bloc_line {
  display: flex;
}

.page_liste-achats .recap_liste_achat.products_bloc_line {
  justify-content: flex-start;
  margin-left: -1rem;
  margin-right: -1rem;

  .card_product {
    width: calc(25% - 2rem);

    .card_product_content {
      padding: 1.8rem;

      form,
      .block_quantity {
        width: 100%;
      }
    }
  }
}

.box_espace_client .products_list_line {
  .stock {
    margin-left: .8rem;
  }

  .block_quantity {
    width: 12rem;
  }

  .card_product .infos_product {
    padding-left: .8rem;

    .product_name {
      padding-left: 0;
    }

    .add_cart_catalogue .button .icon {
      margin-right: 0;
    }
  }
}

/* Espace personnel Mes documents */
#searchDocument {
  .form-inline {
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    select,
    input {
      display: inline-block;
      height: 5rem;
      width: 20rem;
      font-size: 1.6rem;
      color: $color16;

      &.wide-select {
        width: 22.5rem;
      }

      &::placeholder {
        color: $color17;
      }
    }

    input[type="submit"] {
      color: #fff;
      width: 15rem;
      height: 4.8rem;
      line-height: .7;
      margin-top: .4rem;
      font-size: 1.2rem;

      &:hover {
        color: var(--color1);
      }
    }
  }
}

/* Espace personnel Détail commande/devis */
.page_detail {
  .h3 {
    margin-bottom: .5rem;
  }

  .h2 {
    font-size: 1.6rem;
    text-transform: uppercase;

    .icon {
      font-size: 2rem;
    }
  }

  .detail-produits {
    width: 100%;

    .product_line {
      align-items: center;
    }
  }
}

.detail-content {
  .border-card {
    max-width: 38rem;
    height: 100%;
    text-align: center;
    border: .1rem solid var(--color1);
    padding-top: 0.6rem;
    padding-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .infos-detail {
    display: flex;
    align-items: center;

    .h2 {
      margin-right: 3rem;
      width: 13rem;
    }
  }
}

/* Connexion obligatoire */
.auth_b2b {
  .login_page {
    height: 100vh;
  }
}

@media (max-width: $breakpoint-xl) {
  .header_espace_client .h4 a {
    font-size: 1.5rem;
    padding-left: .2rem;
    padding-right: .2rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .main_page .espace_client_col_left .text_login_c .text_login_content {
    padding: 2rem;
  }
  .box_espace_client .box_espace_client_content .grey_block {
    flex-wrap: wrap;

    .header_depot_select {
      width: 100%;
      margin-top: 2rem;
    }
  }
  .header_espace_client .h4 a {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.1;
    height: 6.5rem;
  }
  .box_espace_client .box_espace_client_content .detail-produits .recap_article_content .block_panier_desc {
    width: calc(100% - 10rem);
  }
}

@media (max-width: $breakpoint-md) {
  .main_page {
    .espace_client_col_right {
      margin-bottom: 5rem;
      height: auto;
    }

    .espace_client_col_left .text_login_c {
      height: 35rem;
    }
  }
  #home_backoffice .card_gestion_backoffice_c .card_gestion_backoffice .card_gestion_content {
    .card_gestion_bribe {
      font-size: 1.6rem;
      height: 9.6rem;
      display: flex;
      align-items: center;
    }

    .icon {
      font-size: 4rem;
      margin-right: 2rem;
    }
  }
  .box_espace_client {
    padding: 1rem;

    .box_espace_client_content {
      padding: 2rem 1rem 4rem;

      .grey_block {
        margin-bottom: 2rem;
      }

      .input-effect {
        margin-left: 0;
      }

      .recap_article_c {
        overflow-y: auto;

        .recap_article_content {
          min-width: 80rem;

          .block_panier_desc {
            width: calc(100% - 55rem);
          }
        }
      }

      .table_espace_client_c {
        overflow-y: auto;

        .table_espace_client {
          min-width: 80rem;
        }
      }
    }
  }
  .page_mes-produits-favoris .box_espace_client .box_espace_client_content .recap_article_content {
    min-width: 0;

    .block_panier_desc {
      width: calc(100% - 22rem);
    }
  }
  .page_detail .col-left {
    order: 1;
    margin-top: 2rem;
  }
  .page_liste-achats .recap_liste_achat.products_bloc_line .card_product {
    width: calc(100% / 3 - 1rem);
    margin-left: .5rem;
    margin-right: .5rem;

    .card_product_content {
      padding: .8rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .header_espace_client {
    flex-wrap: wrap;

    .h4 {
      width: 100%;

      a {
        border-bottom-color: $color7;
        height: 4.5rem;

        &.current {
          color: var(--color2);
        }
      }
    }
  }
  #home_backoffice .card_gestion_backoffice_c {
    padding-top: 2rem;

    .card_gestion_backoffice .card_gestion_content {
      margin-top: 1.5rem;
      margin-bottom: 2rem;

      .card_gestion_bribe {
        height: auto;
      }
    }
  }
  .register_page .file_container_c {
    flex-direction: column;
  }
  .file_container + .file_container {
    margin-left: 0;
  }
  .custom_form .backlink.button-filed + .button-cta {
    margin-left: 0;
  }
  .page_liste-achats .recap_liste_achat.products_bloc_line .card_product {
    width: calc(100% / 2 - 1rem);
  }
}

@media (max-width: $breakpoint-xs) {
  #home_backoffice .h2 {
    font-size: 3.2rem;
    margin-bottom: 2rem;
  }
  .box_espace_client .box_espace_client_content {
    padding-bottom: 2rem;

    .h3 {
      font-size: 2.5rem;
    }

    .img-liste-achat-c {
      height: 8rem;
    }

    .button-cta {
      padding-left: .5rem;
      padding-right: .5rem;
      margin-top: 1rem;
    }
  }
  .main_page .espace_client_col_left .text_login_c {
    height: 50rem;

    .text_login_content {
      width: calc(100% - 2rem);
      overflow-y: auto;
      height: 31rem;
      margin-top: 6.2rem;
      max-width: 100%;
      word-break: break-word;
      padding: 1rem;
    }
  }
  .register_page {
    padding: 0;

    .bloc_register {
      padding-left: 1rem;
      padding-right: 1rem;

      .liste-register {
        padding-left: 1.5rem;

        li {
          width: auto;
          line-height: 1.2;
          margin-bottom: .8rem;
        }
      }
    }
  }
  .page_mes-produits-favoris .box_espace_client .box_espace_client_content .recap_article_content .block_panier_desc {
    min-width: calc(100% - 10rem);
  }
  .page_liste-achats .recap_liste_achat.products_bloc_line .card_product {
    width: calc(100% - 1rem);
  }
  #searchDocument .form-inline input,
  #searchDocument .form-inline select {
    width: 22.5rem;
  }
  #home_backoffice .card_tarif_client {
    .button-container .loader {
      position: static;
      margin-top: .8rem;
    }

    .button {
      width: 100%;
    }
  }
  .row-espace-client {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
  }
  #home_backoffice .card_tarif_client {
    width: 100%;
  }
}