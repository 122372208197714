// Couleurs 1-2-3 à modifier dans le BO
// $color1 : #0d66a2; On utilise var(--color1)
//$color2 : #B20C0C; On utilise var(--color2)
//$color3 : #CBA426; On utilise var(--color3)
//$color4: #6FACD5; menu produit et recherche, On utilise var(--color4)
$color5: #DFDFDF;
$color6: #E7E7E7;
$color7: #F9F9F9; //bg sections
$color8: #ECF3F8;
$color9: #CBCBC9;
$color10: #E8E5E5;
$color11: #302D29;
$color12: #9E9E9E;
$color13: #6FACD5;
$color14: #F1F2F2;
$color15: #f59a10;
$color16: #777;
$color17: #BBB;
$color18: #212121;
$color19: #b6163b;

.color1{
  color: var(--color1);
}

a:hover{
  color: var(--color2);
}