.products_list_card {
  margin-right: -1rem;
  margin-left: -1rem;

  .card_product {
    background-color: #fff;

    .card_product_content {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;

      .img_block {
        @include flex_center;
        height: 23rem;
        margin: 0;

        .flag_new_product {
          left: 1rem;
          padding: 0.4rem 1.4rem;
        }
      }

      .infos_product {
        background-color: #fff;
        padding: 1rem;

        .prix_client {
          .global_price {
            color: $color16;
          }
        }

        .price {
          font-size: 1.6rem;
          font-weight: 700;
          color: var(--color1);

          .old-price {
            margin-left: 1.2rem;
          }

          .loader {
            position: absolute;
            right: 15%;
          }
        }

        .add_cart_catalogue {
          margin-top: 1rem;

          .button {
            @include flex_center;
            padding: .8rem .5rem;
            text-align: center;
            width: 100%;
            line-height: 1.2;

            &:has(.icon) {
              padding: .5rem;
            }

            .icon {
              display: inline-flex;
            }
          }
        }
      }
    }
  }
}

.products_list_card_global {
  display: flex;
  flex-wrap: wrap;

  .card_product {
    margin-bottom: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    box-shadow: 0 .6rem .5rem 0 $color5;

    .card_product_content {
      .img_block img {
        max-height: 23rem;
        width: 100%;
        object-fit: contain;
      }

      .img-campagne {
        position: absolute;
        top: .5rem;
        right: .5rem;
        width: 5rem;
        z-index: 1;
        pointer-events: none;
      }

      .infos_product {
        .product_name {
          color: var(--color1);
          font-weight: 600;
          height: 6.3rem;
          margin-bottom: 1rem;

          a {
            @include line-clamp(3);
          }
        }

        .prix_client .highlight {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 1.1;
          color: var(--color1);
          margin-top: 1rem;
        }

        .add_cart_catalogue .button .icon {
          font-size: 2.5rem;
          margin-right: 1rem;
        }

        .highlight {
          font-weight: 600;
        }
      }
    }
  }
}

.page_catalogue .products_list_card .card_product,
.page_produit .products_list_card .card_product {
  width: calc(25% - 2rem);
}

.old-price {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -.2rem;
    left: -.2rem;
    border-top: 1px solid var(--color1);
  }
}

.stock {
  color: $color16;
  display: flex;
  align-items: center;
  font-weight: 600;

  .icon-stock {
    display: inline-block;
    width: 1.4rem;
    min-width: 1.4rem;
    height: 1.4rem;
    margin-left: 1rem;
    background-color: #7EBC00;
    border-radius: 50%;

    &.icon-outstock {
      background-color: #e2001a;
    }

    &.icon-not-available {
      background-color: $color15;
    }

    &.icon-command {
      background-color: $color18;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .page_catalogue .products_list_card .card_product,
  .page_produit .products_list_card .card_product {
    width: calc(100% / 3 - 2rem);
  }
}

@media (max-width: $breakpoint-lg) {
  .products_list_card .card_product .infos_product .add_cart_catalogue .button .icon {
    font-size: 2rem;
    margin-right: 0.5rem;
    letter-spacing: 0;
  }

  .page_catalogue .products_list_card .card_product,
  .page_produit .products_list_card .card_product {
    width: calc(50% - 1rem);
  }

  .products_list_card .card_product {
    margin-right: .5rem;
    margin-left: .5rem;
  }
}

@media (max-width: 470px) {
  .products_list_col .header_products_list .orga_products > div:not(.catalogue_disposition_produits) {
    width: 100%;
  }
  .products_list_card .card_product .infos_product {
    .add_cart_catalogue .button {
      padding: .4rem .6rem;
    }
  }
  .page_catalogue .products_list_card .card_product,
  .page_produit .products_list_card .card_product {
    width: 100%;
  }
  .products_list_col.table-list .products_list_line form {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
}